import PropTypes from 'prop-types';
import * as React from 'react';
import { css } from "@emotion/core";
import ProfileImagePlaceholder from './ProfileImagePlaceholder';

class ProfileImage extends React.Component {
  state = {};

  render() {
    return this.props.imageUrl ? (
      <img
        css={css`
            width: ${this.props.size}px;
            height: ${this.props.size}px;
            border-radius: ${this.props.size / 2}px;
            background-image: url('${this.props.imageUrl}');
            margin: ${this.props.margin};
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

          `}
        alt=""
      />
    ) : (
      <ProfileImagePlaceholder
        size={this.props.size}
        color={this.props.placeholderColor}
        margin={this.props.margin}
        display={this.props.display}
      />
    );
  }
}

ProfileImage.defaultProps = {
  size: 80,
  placeholderColor: '#000',
  margin: null,
  display: 'block',
  imageUrl: null,
};

ProfileImage.propTypes = {
  imageUrl: PropTypes.string,
  size: PropTypes.number,
  placeholderColor: PropTypes.string,
  margin: PropTypes.string,
  display: PropTypes.string,
};

export default ProfileImage;
